.awssld {
    --slider-transition-duration: 770ms;
    --organic-arrow-thickness: 13px;
    --organic-arrow-border-radius: 12px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;

    
    @media screen and (max-width: 450px) {
        --slider-height-percentage: 100%;
    }

    @media screen and (min-width: 451px) {
        --slider-height-percentage: 50%;
    }
}
  
.video__show {
    transform: scaleX(1.2);
    -webkit-transform: scaleX(1.2);
    -moz-transform: scaleX(1.2);
}