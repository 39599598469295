.awssld {
    --slider-height-percentage: 50%;
    --slider-transition-duration: 770ms;
    --organic-arrow-thickness: 13px;
    --organic-arrow-border-radius: 12px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  
.video__show {
    transform: scaleX(1.2);
    -webkit-transform: scaleX(1.2);
    -moz-transform: scaleX(1.2);
}

.video__info{
    h4{
        font-weight: 500;
    }
    
}

@media(max-width: 700px){
   iframe{
       width: 122%!important;
   }
   .video__info{
       top: -30%;
   }
   .video__show {
    transform: scaleX(1.0);
    -webkit-transform: scaleX(1.0);
    -moz-transform: scaleX(1.0);
    }
    .awssld {
        --slider-height-percentage: 60%;
    }
}