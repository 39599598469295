@import url("https://fonts.googleapis.com/css?family=Playfair+Display:700|IBM+Plex+Sans:500&display=swap");

:root {
  --color-primary: #c61419;
  --color-secondary: #101118;
  --color-accent: #1d1f2f;
  --color-focus: #c61419;
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
}

// =========================
// Global
// =========================

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

#app {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
}

.visuallyhidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

// =========================
// Slider controls
// =========================

.slider__controls {
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100% + 1rem);
  width: 100%;

  .icon {
    fill: var(--color-primary);
    width: 100%;
  }

  .btn {
    background-color: var(--color-primary);
    border: none;
    border-radius: 0.125rem;
    color: white;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    padding: 1rem 2.5rem 1.125rem;

    --size: 3rem;

    align-items: center;
    background-color: white;
    border: 3px solid transparent;
    border-radius: 100%;
    display: flex;
    height: var(--size);
    padding: 0;
    width: var(--size);

    &:focus {
      border-color: var(--color-focus);
      outline: none;
      outline-color: var(--color-focus);
      outline-offset: 2px;
      outline-style: solid;
      outline-width: 3px;
    }

    &:active {
      transform: translateY(1px);
    }

    &--previous > * {
      transform: rotate(180deg);
    }
  }
}

// =========================
// Slider
// =========================

.slider {
  --slide-size: 70vmin;
  --slide-margin: 8vmin;

  height: var(--slide-size);
  margin: 0 auto;
  position: relative;
  width: var(--slide-size);

  @media screen and (max-width: 1024px){
    --slide-size: 55vmin !important;
  }
}

.slider__wrapper {
  display: flex;
  margin: 0 calc(var(--slide-margin) * -1);
  position: absolute;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}

// =========================
// Slide
// =========================

.slide {
  align-items: center;
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: var(--slide-size);
  justify-content: center;
  margin: 0 var(--slide-margin);
  opacity: 0.25;
  position: relative;
  text-align: center;
  transition: opacity calc(var(--base-duration) / 2) var(--base-ease),
    transform calc(var(--base-duration) / 2) var(--base-ease);
  width: var(--slide-size);
  z-index: 1;
  
  outline: 0px !important;

  &--previous,
  &--next {
    &:hover {
      opacity: 0.6;
    }
  }

  &--previous {
    cursor: grab;

    &:hover {
      transform: translateX(2%);
    }
  }

  &--next {
    cursor: grab;
    z-index: 0;

    &:hover {
      transform: translateX(-2%);
    }
  }
}

.slide--current {
  --x: 0;
  --y: 0;
  --d: 50;

  opacity: 1;
  pointer-events: auto;
  user-select: auto;

  @media (hover: hover) {
    &:hover .slide__image-wrapper {
      transform: scale(1.04)
        translate(
          calc(var(--x) / var(--d) * 1px),
          calc(var(--y) / var(--d) * 1px)
        );
    }
  }
}

.slide__image-wrapper {
  // =========================
  // Icons
  // =========================
  div {
    opacity: 0.9;
    width: 100%;
    height: 100%;
  }

  .icon {
    fill: var(--color-primary);
    width: 100%;
  }

  // =========================
  // Buttons
  // =========================

  .btn {
    background-color: var(--color-primary);
    border: none;
    border-radius: 0.125rem;
    color: white;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    padding: 1rem 2.5rem 1.125rem;

    &:focus {
      outline-color: var(--color-focus);
      outline-offset: 2px;
      outline-style: solid;
      outline-width: 3px;
    }

    &:active {
      transform: translateY(1px);
    }
  }

  background-color: var(--color-accent);
  border-radius: 1%;
  height: 100%;
  //   left: 0%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  width: 180%;
}

.slide__container {
  background-color: transparent;
  border-radius: 1%;
  height: 100%;
  width: 180%;
}

.slide__loading {
  margin-top: 11rem;
  @media screen and (max-width: 480px) {
    margin-top: 4rem !important;
  }
}

.slide__image {
  --d: 20;

  height: 110%;
  left: -5%;
  object-fit: cover;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -5%;
  transition: opacity var(--base-duration) var(--base-ease),
    transform var(--base-duration) var(--base-ease);
  user-select: none;
  width: 110%;

  @media (hover: hover) {
    .slide--current & {
      transform: translate(
        calc(var(--x) / var(--d) * 1px),
        calc(var(--y) / var(--d) * 1px)
      );
    }
  }
}

.slide__headline {
  font-size: 8vmin;
  font-weight: 600;
  position: relative;
}

.slide__content {
  --d: 60;

  opacity: 0;
  padding: 4vmin;
  position: relative;
  transition: transform var(--base-duration) var(--base-ease);
  visibility: hidden;

  

  .slide--current & {
    animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
    visibility: visible;

    @media (hover: hover) {
      transform: translate(
        calc(var(--x) / var(--d) * -1px),
        calc(var(--y) / var(--d) * -1px)
      );
    }
  }

  @media screen and (max-width: 480px){
    > * + * {
      margin-top: 4rem !important;
    }
  }

  > * + * {
    margin-top: 11rem;
  }
}

// =========================
// Animations
// =========================

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
