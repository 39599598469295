@media (max-width: 435px) {
  .video__info {
    position: absolute;
    z-index: 4;
    left: 17%;
    top: 6%;
    width: 69%;    
    padding-top: 150px;
    padding-right: 0px;
  }
}
@media (min-width: 436px) {
  .video__info {
    position: absolute;
    z-index: 4;
    left: 34%;
    top: 38%;
    width: 35%;
  }
}

.video__show {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 94%;
}

.dark__header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: black;
  width: 100%;
  height: 94%;
  opacity: 0.4;
}
